import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { documentoFiscalStore, financeiroCategoriaStore, } from '@/store';
import { Validator, } from 'vee-validate';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.id = null;
        this.entityObject = {};
        this.FinanceiroCategoriaSelected = { id: 0 };
        this.financeiroParcelasHeader = [
            {
                text: 'Parcela',
                value: 'parcela',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Valor',
                value: 'valor_atualizado',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Status',
                value: 'status_id',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                align: 'left',
            },
        ];
        this.estoquesHeader = [
            {
                text: 'Livro',
                value: 'livro',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Quantidade',
                value: 'quantidade',
                align: 'left',
                filterable: true,
            },
        ];
    }
    get FinanceiroCategoriasOptions() {
        return financeiroCategoriaStore.FinanceiroCategorias;
    }
    showParcela(item) {
        this.$router.push({
            name: 'main-contasapagar-edit',
            params: { id: item.id },
        });
    }
    async mounted() {
        this.loading = true;
        this.FinanceiroCategoriaSelected = null;
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await documentoFiscalStore.getDocumentoFiscal(+this.id);
            this.FinanceiroCategoriaSelected =
                // @ts-ignore
                this.entityObject.financeiro_categoria_id;
        }
        this.loading = false;
    }
};
Create = __decorate([
    Component({
        components: {
            DateFieldComponent,
        },
    })
], Create);
export default Create;
